var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon
      }
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              color: "cardPrimary",
                              title: "Identification"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.countryReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "ctyDsc",
                                        "item-value": "ctyCod",
                                        label: "Country",
                                        mandatory: "",
                                        name: "countryCode",
                                        "show-item-avatar": "",
                                        sortable: ""
                                      },
                                      model: {
                                        value: _vm.formData["countryCode"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "countryCode",
                                            $$v
                                          )
                                        },
                                        expression: "formData['countryCode']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "National Id",
                                        name: "nationalId"
                                      },
                                      model: {
                                        value: _vm.formDataF["nationalId"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "nationalId",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['nationalId']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Full Name",
                                        name: "fullName"
                                      },
                                      model: {
                                        value: _vm.formDataF["fullName"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "fullName",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['fullName']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Short Name",
                                        name: "shortName"
                                      },
                                      model: {
                                        value: _vm.formDataF["shortName"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "shortName",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['shortName']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3", md: "3" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Start Date",
                                        name: "startDate"
                                      },
                                      model: {
                                        value: _vm.formDataF["startDate"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "startDate",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['startDate']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3", md: "3" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Expiry Date",
                                        name: "expiryDate"
                                      },
                                      model: {
                                        value: _vm.formDataF["expiryDate"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "expiryDate",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['expiryDate']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { color: "cardPrimary", title: "Details" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.personTypeReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "description",
                                        "item-value": "code",
                                        label: "Person Type",
                                        mandatory: "",
                                        name: "personTypeCode"
                                      },
                                      model: {
                                        value: _vm.formData["personTypeCode"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "personTypeCode",
                                            $$v
                                          )
                                        },
                                        expression: "formData['personTypeCode']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Date of establishment/birth",
                                        name: "establishmentDate"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["establishmentDate"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "establishmentDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['establishmentDate']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.aeoStatusReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "description",
                                        "item-value": "code",
                                        label: "AEO Status",
                                        mandatory: "",
                                        name: "aeoStatusCode"
                                      },
                                      model: {
                                        value: _vm.formData["aeoStatusCode"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "aeoStatusCode",
                                            $$v
                                          )
                                        },
                                        expression: "formData['aeoStatusCode']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload":
                                          _vm.economicActivityReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "description",
                                        "item-value": "code",
                                        label: "Principal Economic Activity",
                                        mandatory: "",
                                        name: "principalEconomicActivityCode"
                                      },
                                      model: {
                                        value:
                                          _vm.formData[
                                            "principalEconomicActivityCode"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "principalEconomicActivityCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['principalEconomicActivityCode']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "VAT Identification Number",
                                        name: "vatIdNumber"
                                      },
                                      model: {
                                        value: _vm.formDataF["vatIdNumber"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "vatIdNumber",
                                            $$v
                                          )
                                        },
                                        expression: "formDataF['vatIdNumber']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6", md: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.legalStatusReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "description",
                                        "item-value": "code",
                                        label: "Legal Status",
                                        mandatory: "",
                                        name: "legalStatusCode"
                                      },
                                      model: {
                                        value: _vm.formData["legalStatusCode"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "legalStatusCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['legalStatusCode']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              color: "cardPrimary",
                              title: "Third Party Identification Number(s)"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("OperatorsThirdPartyIds", {
                                      attrs: {
                                        "is-disabled-func": _vm.isDisabled,
                                        "item-headings": [
                                          "Third Party Code",
                                          "Identifier",
                                          "Supply Chain Role",
                                          "Start Date",
                                          "End Date"
                                        ],
                                        "item-values": [
                                          "thirdPartyCode",
                                          "idNumber",
                                          "supplyChainRole",
                                          "startDate",
                                          "endDate"
                                        ],
                                        readonly: _vm.isReadonly,
                                        name: "eoriThirdPartyIdNumbers",
                                        title: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF[
                                            "eoriThirdPartyIdNumbers"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "eoriThirdPartyIdNumbers",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['eoriThirdPartyIdNumbers']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              color: "cardPrimary",
                              title: "Economic Activities"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("OperatorsActivities", {
                                      attrs: {
                                        "is-disabled-func": _vm.isDisabled,
                                        "item-headings": [
                                          "Activity Code",
                                          "Activity Name",
                                          "Start Date",
                                          "End Date"
                                        ],
                                        "item-values": [
                                          "activityCode",
                                          "activityName",
                                          "startDate",
                                          "endDate"
                                        ],
                                        readonly: _vm.isReadonly,
                                        name: "eoriEconomicActivities",
                                        title: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF[
                                            "eoriEconomicActivities"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "eoriEconomicActivities",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['eoriEconomicActivities']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              color: "cardPrimary",
                              title: "Communication/Contact Information"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("OperatorsContacts", {
                                      attrs: {
                                        "is-disabled-func": _vm.isDisabled,
                                        "item-headings": [
                                          "Eori Number",
                                          "Full Name",
                                          "Phone",
                                          "Fax",
                                          "Email",
                                          "Start Date",
                                          "End Date"
                                        ],
                                        "item-values": [
                                          "eoriNumber",
                                          "fullName",
                                          "phoneNumber",
                                          "faxNumber",
                                          "email",
                                          "startDate",
                                          "endDate"
                                        ],
                                        readonly: _vm.isReadonly,
                                        name: "eoriContactInformations",
                                        title: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF[
                                            "eoriContactInformations"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "eoriContactInformations",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['eoriContactInformations']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              color: "cardPrimary",
                              title: "Related Locations"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("OperatorsLocations", {
                                      attrs: {
                                        "is-disabled-func": _vm.isDisabled,
                                        "item-headings": [
                                          "Function",
                                          "Name",
                                          "Street",
                                          "Post Code",
                                          "City",
                                          "Country",
                                          "Authorised Location",
                                          "Start Date",
                                          "End Date"
                                        ],
                                        "item-values": [
                                          "locationFunctionCode",
                                          "shortName",
                                          "streetAndNumber",
                                          "postCode",
                                          "city",
                                          "countryCode",
                                          "authorisedLocationCode",
                                          "startDate",
                                          "endDate"
                                        ],
                                        readonly: _vm.isReadonly,
                                        name: "eoriRelatedLocations",
                                        title: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["eoriRelatedLocations"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "eoriRelatedLocations",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['eoriRelatedLocations']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }